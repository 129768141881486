.title {
  $el: &;

  &--h1 {
    @extend %title-base;
    font-size: rem(24px) !important;
    margin-bottom: 0;
  }

  %title-h2,
  &--h2 {
    @extend %title-base;
    font-size: rem(18px) !important;
  }

  &--h3 {
    @extend %title-base;
    font-size: rem(14px) !important;
    margin-top: 0 !important;
  }

  &--h4 {
    @extend %title-base;
    font-size: rem(12px) !important;
  }

  &--center {
    text-align: center;
  }

  &--bottom {
    margin-bottom: rem(10px) !important;
  }
}

%title-base {
  font-weight: bold !important;
  ::first-letter {
    text-transform: uppercase;
  }
}

%title-h2 {
  @extend %title-base;
  font-size: rem(18px) !important;
}
