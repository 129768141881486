.favorites {
  $el: &;

  &--container {
    height: 100%;
    width: 100%;
  }

  &--pane {
    display: flex;
    width: 100%;
    justify-content: center;

    @include breakpoints(mdl) {
      display: none;
    }

    .ant-tabs-nav-operations {
      display: none !important;
    }
  }

  &--space {
    height: rem(52px);
    width: 100%;
  }
}

.ant-tabs-nav::before {
  border: none !important;
}
