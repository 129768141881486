.card {
  $el: &;

  &--container {
    width: 100%; /* la largeur est definie par le parent */
    border: none;
    border-radius: rem(16px);
    overflow: hidden;

    .ant-card-body {
      padding: rem(10px) rem(10px) rem(20px);
    }

    .ant-card-cover {
      margin-top: 0 !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }

  &--background {
    position: relative;
    width: 100%; /* la largeur est definie par le parent */
    height: 0;
    padding-bottom: 125%; /* calcul de la hauteur en fonction de la largeur du parent */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &--image {
    width: 100%;
  }

  &--image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--image-blur {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
  }

  &--blur {
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }

  &--title {
    margin-top: 0 !important;
    margin-bottom: rem(10px);
    text-align: left !important;
    @include breakpoints(md) {
      text-align: center !important;
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &--text {
    line-height: rem(16px);
    margin-bottom: rem(12px);
    text-align: left !important;
    @include breakpoints(md) {
      text-align: center !important;
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &--price--promotion {
    font-size: rem(12px) !important;
    margin-top: 0 !important;
  }
  &--price--original {
    text-decoration: line-through;
  }
  &--price--percent {
    margin-left: rem(8px);
  }

  &--favorite {
    position: absolute;
    top: rem(14px);
    right: rem(14px);

    svg {
      height: rem(16px);
      width: rem(16px);
    }
  }
  &--actions {
    width: 100%;
    padding: 0 rem(8px);
    & > button:first-child {
      margin-bottom: rem(8px);
    }
    & > .card--add-to-cart {
      margin-top: rem(8px);
    }
  }

  // Button
  &--light {
    @extend %button-small-base;
    border-radius: rem(8px);
    width: 100%;
    background-color: $background !important;
    color: #1c1c28;
    border: 1px solid var(--scrollbar-color) !important;

    & > span {
      display: table;
      font-weight: 500;
      font-size: rem(14px);
      line-height: rem(24px);
      color: $darkcolor;
    }
  }

  &--dark {
    @extend %button-small-base;
    border-radius: rem(8px);
    background-color: #000000 !important;
    width: 100%;
    border: none;

    & > span {
      display: table;
      font-weight: 600;
      font-size: rem(14px);
      line-height: rem(24px);
      color: $lightcolor;
    }
  }

  &--favorites_options {
    display: flex;
    flex-direction: column;
    & > div {
      margin-bottom: rem(18px);
    }
  }

  &-cart--add {
    display: inline-block;
    margin-top: rem(10px);

    @include breakpoints(mdl) {
      display: none;
    }
  }
}
