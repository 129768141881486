.look {
  $el: &;

  &--head {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: rem(40px);
    justify-content: inherit;
    .look--switch:first-child {
      margin-right: rem(20px);
    }
  }

  &--container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1.25rem;

    &-swipe{
      padding: 0;

      .look--head {
        max-width: 500px;
      }
    }

    @include breakpoints(sm) {
      padding: 0;
    }

    @include breakpoints(smd) {
      padding: 0 1.25rem;
    }

    @include breakpoints(mdl) {
      padding: 0;
    }
    
    @include breakpoints(xlg) {
      padding: 0 1.25rem;
    }
  }

  &--swipe-content {
    display: flex;
    height: auto;
    width: 100%;
    justify-content: center;
  }

  &--content {
    display: flex;
    height: auto;
    width: 100%;
    justify-content: center;
  }

  &--switch {
    @extend %text-base;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #8f90a6;
    & > span {
      margin-right: rem(12px);
    }
  }

  &--switch--row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex: 1;
  }

  &--gutter {
  }

  &--button {
    width: 100%;
    min-height: 40px;
    border-radius: 8px;
    @extend %text-base;
    font-weight: 500 !important;
    font-size: rem(13px);
    line-height: rem(15px);
    margin-top: rem(20px);
    margin-bottom: rem(20px);
    
    &-swipe {
      @include breakpoints(sm) {
        display: none;
      }
    }

  }
}
