.cart {
  $el: &;

  &--container {
    min-height: 100%;
    width: 100%;
    padding: 16px;
  }

  &--empty {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  &--space {
    height: rem(32px);
    width: 100%;
  }

  &--item {
    padding-top: rem(0px);
    @include breakpoints(sm) {
      padding-top: rem(0px);
    }
  }

  &--button {
    display: flex;
    flex-direction: column;

    @include breakpoints(sm) {
      flex-direction: row;
    }

    button {
      min-height: rem(48px);
      margin-bottom: rem(12px);
      @include breakpoints(sm) {
        margin-bottom: 0;
      }
      &:first-child {
        margin-right: rem(20px);
      }
    }
  }

  &--total {
    display: none;
    flex-direction: column;
    padding-left: 0%;

    @include breakpoints(mdl) {
      display: flex;
      padding-left: 50%;
    }
  }

  &--text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    margin-top: rem(20px);
    margin-bottom: rem(10px);
    @include breakpoints(sm) {
      flex-direction: row;
      justify-content: flex-end;
      align-content: center;
      align-items: flex-end;
    }

    & > div {
      @include breakpoints(sm) {
        margin-right: rem(42px);
      }
    }

    h1 {
      margin-top: 0 !important;
      margin-bottom: 0;
    }
  }
}
