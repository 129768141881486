.collapse {
  $el: &;

  width: 100%;

  &--panel {
  }

  &--container {
    width: 100% !important;
    background: $background;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    border-radius: 10px !important;
    border: none;
    overflow: hidden;
  }

  .ant-collapse-arrow {
    right: rem(12px) !important;
  }

  .ant-collapse-content {
    border-radius: 10px !important;
  }
  .ant-collapse-header {
    padding: 10px rem(11px) !important;
    // min-height: rem(40px);
  }
  .ant-collapse {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
    font-weight: 500 !important;
    font-size: rem(14px);
    line-height: rem(24px);
    color: #1c1c1c;
    border: none !important;
    border-radius: 8px;
  }

  .ant-collapse-content {
    border: none;
  }

  &--bubble-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: rem(20px) 0 rem(10px);
  }

  &--bubble {
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
    border-radius: 24px;
    width: auto;
    padding: rem(6px) rem(25px);
    font-weight: 500;
    font-size: rem(12px);
    line-height: rem(16px);
    text-align: center;
    letter-spacing: 0.2px;

    color: #1c1c28;
  }

  &--checkbox-container {
    display: flex;
    flex-direction: column;

    .ant-checkbox-wrapper {
      margin-left: rem(8px);
    }

    .ant-collapse-content-box {
      display: flex;
      flex-direction: column;
      padding-top: 0px !important;
    }
  }
}
