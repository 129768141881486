.breadcrumb {
  $el: &;

  &--container {
    margin-bottom: 0;
    .custom-breadcrumb-separator,
    .custom-breadcrumb-link {
      // margin-right: rem(15px);
    }
    .custom-breadcrumb-separator {
      svg {
        color: #8f90a6;
        width: rem(9px);
        height: rem(9px);
        font-weight: 600;
      }
    }

    // span {
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    // }

    & > span:last-child {
      .breadcrumb--item {
        color: var(--scrollbar-color);
      }
      .custom-breadcrumb-separator {
        display: none;
      }
    }
  }

  &--item {
    font-style: normal;
    font-weight: 500;
    font-size: rem(13px);
    line-height: rem(26px);
    color: #8f90a6;
  }

  &--row {
    display: flex;
    width: auto;
    flex-direction: row;
    align-items: center;
    align-content: center;
    height: 100%;
  }

  &--separator {
    height: 100%;
    width: 1.2px;
    background-color: #f7f7fa;
    padding: 10px 0;
    margin: auto rem(20px);
  }

  &--back {
    border: none !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: rem(13px) !important;
  }
}
