.thumbnail {
  $el: &;

  &--image-container {
    position: relative;
    height: 100%;
    width: 100%;
  }

  &--row {
    display: none;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    @include breakpoints(sm) {
      display: flex;
    }
  }

  &--background {
    position: relative;
    width: 100%; /* la largeur est definie par le parent */
    height: 0;
    padding-bottom: 125%; /* calcul de la hauteur en fonction de la largeur du parent */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &--blur {
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }

  &--actions {
    width: 100%;
    & > button:first-child {
      margin-bottom: rem(8px);
    }
  }

  // Button
  &--light {
    border-radius: rem(8px);
    width: 100%;
    background-color: $background !important;
    color: #1c1c28;
    border: 1px solid var(--scrollbar-color) !important;
    height: 25px;
    padding: 0px 0px;

    & > span {
      display: table;
      font-weight: 500;
      font-size: rem(11px);
      line-height: rem(14px);
      color: $darkcolor;
    }
  }

  &--dark {
    border-radius: rem(8px);
    background-color: #000000 !important;
    width: 100%;
    border: none;
    height: 25px;
    padding: 0px 0px;

    & > span {
      display: table;
      font-weight: 600;
      font-size: rem(11px);
      line-height: rem(14px);
      color: $lightcolor;
    }
  }

  &--image-blur {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
  }

  &--item {
    background: #ffffff;
    border-radius: 16px;
    height: rem(70px);
    width: rem(54px);
    overflow: hidden;

    @include breakpoints(sm) {
      height: rem(80px);
      width: rem(64px);
    }

    @include breakpoints(md) {
      height: rem(90px);
      width: rem(74px);
    }
    @include breakpoints(lg) {
      width: rem(98px);
      height: rem(114px);
    }

    .ant-card-cover {
      height: 100%;
      width: 100%;
    }
  }

  &--image {
    width: 100%;
    height: 100%;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .ant-card-body {
    padding: 0;
  }
}
