.filters {
  $el: &;

  &--container {
    margin-bottom: rem(30px);
    display: none;
    @include breakpoints(mdl) {
      display: flex;
    }
  }

  &--responsive {
    display: flex;
    flex-direction: column;

    & > div {
      margin-bottom: rem(10px);
    }
  }

  &--mobile {
    display: none;
  }

  &--container-mobile {
  }

  .ant-tabs {
    width: 100%;
    align-items: center;
  }
}

.ant-select-selection-search-input {
  font-size: 16px !important;
}
