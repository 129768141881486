.menu {
  $el: &;

  &--container {
    height: 100%;
    flex: 1;
  }

  &--item {
    padding: 0 20px !important;
    &::after {
      left: 0 !important;
      width: 100% !important;
    }
  }
}

.ant-menu-horizontal {
  border-bottom: none !important;
}
