.select {
  $el: &;

  .ant-select-selector,
  .ant-select-selection-placeholder,
  ant-select-item ant-select-item-option,
  .ant-select-selection-item {
    font-weight: 500 !important;
    font-size: rem(14px);
    line-height: rem(24px);
    color: #1c1c1c;
  }

  .ant-select-arrow {
    color: #1c1c1c;
    svg {
      color: #1c1c1c;
    }
  }

  &--container {
    width: 100%;
    background: $background;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    border-radius: 10px !important;
    border: none;

    .ant-select-selector {
      border-radius: 10px !important;
      border: none !important;
    }
  }

  &--text {
    @extend %text-base;
    font-weight: 500;
    font-size: rem(14px);
    line-height: rem(24px);
  }
}

.ant-select-dropdown {
  border-radius: rem(10px) !important;
}

.ant-layout-content {
  position: relative;
}