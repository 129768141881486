.catalog {
  $el: &;

  &--container {
    height: auto;
    margin-bottom: rem(32px);
    z-index: -1;
    padding: rem(20px);
    // max-width: 1280px;

    @include breakpoints(sm) {
      z-index: 99;
    }

    @include breakpoints(md) {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        margin-left: 8px;
        background-color: #f2f2f2;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-clip: content-box;
        background-color: var(--scrollbar-color);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }

  &--empty {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  &--item {
    display: flex;
    justify-content: center;
    @include breakpoints(xxlg) {
    }
  }
}
