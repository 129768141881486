.carousel {
  $el: &;

  .slick-list,
  .slick-track {
    height: 100%;
    min-width: 100%;
    padding: 11px 0px;
  }

  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-list {
    padding: rem(11px) rem(16px);
  }

  .slick-slide {
    opacity: 0.5;
    transform: scale(0.8);

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .slick-slide {
    height: auto;
  }

  .slick-current {
    opacity: 1;
    transform: scale(1);
    transition: all 0.4s ease-in;
    transition: all 0.8s ease-out;
  }

  &--container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    height: 100%;
  }

  &--size {
    height: 100%;
  }

  &--arrow {
    display: flex;
    height: 2rem;
    width: 2rem;
    background: $background;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);

    svg {
      font-size: rem(13px);
      color: var(--scrollbar-color);
    }

    &:before {
      display: none;
    }
  }

  &--left {
    left: 4px;
    z-index: 999;
    @include breakpoints(sm) {
      left: 5px;
    }
  }

  &--right {
    right: 4px;
    z-index: 999;
    @include breakpoints(sm) {
      right: 5px;
    }
  }
}
