.drawer {
  $el: &;

  &--container {
  }

  &--button {
    width: 100%;
    border: none !important;
  }
}

.ant-drawer {
  @include breakpoints(mdl) {
    display: none;
  }
}

.ant-drawer-header {
  display: none;
}
.ant-drawer-content,
.ant-drawer-wrapper-body {
  background: #ebebf0;
}
