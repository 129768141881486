.layout {
  $el: &;

  &--container {
    background-color: $lightcolor;
    height: 100%;
  }

  &--header-row {
    position: fixed;
    z-index: 1;
    width: 100%;
  }

  &--swipe-content {
    padding: rem(20px);
    align-items: center;
    @include breakpoints(sm) {
      .layout--title {
        display: none;
      }
    }
  }

  &--right-content {
    @include breakpoints(sm) {
      padding: rem(20px);
      .layout--title {
        display: none;
      }
    }
  }

  &--with-pageheader {
    height: calc(100vh - 65px);
    overflow: auto;
    padding: rem(20px);
    @include breakpoints(sm) {
      height: calc(100vh - 65px);
    }
  }

  &--with-header {
    height: calc(100vh - 65px);
    overflow-y: auto;
  }

  &--left-content {
    padding: rem(20px);
    height: calc(100vh - 65px);
    background-color: #f7f7fa;
    overflow-x: hidden;
    overflow-y: auto;

    &.layout--with-header {
      height: calc(100vh - 65px);
    }
  }

  &--side-left {
    background-color: #f7f7fa;
  }

  &--side-right {
    background-color: $background;
  }

  &--title {
    display: none;
    @include breakpoints(sm) {
      display: flex;
    }
    min-height: 0 !important;
  }

  &--headroom {
    background-color: #fff;
    padding-top: 20px;
    @include breakpoints(sm) {
      display: none;
    }

    .sticky {
      top: 65px !important;
      z-index: 1;
    }
  }
}
