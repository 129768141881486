.card-cart {
  $el: &;

  &--container {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: rem(179px);
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    box-sizing: border-box;
    padding: rem(16px);
    border-radius: rem(16px);
    margin-bottom: rem(20px);
    overflow: hidden;
    @include breakpoints(sm) {
      flex-direction: row;
      padding: rem(42px) rem(31px) rem(20px);
    }

    &-swipe {
      background-color: white;
      @include breakpoints(sm) {
        border: none;
      }
    }

  }

  &--image {
    width: rem(155px);
    padding-right: 15px;
    margin-bottom: rem(21px);
    @include breakpoints(sm) {
      margin-bottom: 0px;
    }
    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 20px;
  }

  &--color {
    margin-bottom: 10px;
    text-align: left;
  }

  &--col {
    width: 100%;
    display: flex;
    justify-content: center;

    @include breakpoints(sm) {
      align-items: center;
      align-content: center;
    }
  }

  &--flex {
    display: flex;
  }

  &--size {
    flex: 1;
    align-items: center;
    display: flex;
    padding-top: 5px;
  }

  &--title-mobile {
    @include breakpoints(sm) {
      display: none;
    }
  }

  &--title-desktop {
    display: none;
    @include breakpoints(sm) {
      display: block;
    }
  }
}
