.card-model {
  $el: &;

  &--container {
    border: none;
    border-radius: rem(16px);
    overflow: hidden;

    .ant-card-body {
      padding: 0;
    }

    &:hover {
      .card--image {
        filter: blur(3px);
        -webkit-filter: blur(3px);
      }
    }
  }

  &--image {
    width: 100%;
    height: auto;
  }

  &--image-container {
    position: relative;
  }

  &--background {
    position: relative;
    width: 100%; /* la largeur est definie par le parent */
    height: 0;
    padding-bottom: 125%; /* calcul de la hauteur en fonction de la largeur du parent */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &--content {
    // margin: 0px rem(24px);
    .image--dot-container {
      margin: 0 !important;
    }
  }

  &--top {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: rem(10px);
    right: rem(10px);

    svg {
      width: rem(20px);
      height: rem(20px);
    }
  }

  &--bottom {
    display: flex;
    flex-direction: column;
    position: absolute;
    position: absolute;
    bottom: rem(14px);
    right: rem(20px);
    svg {
      width: rem(25px);
      height: rem(25px);
    }
  }

  &--bottom-left {
    display: flex;
    flex-direction: column;
    position: absolute;
    position: absolute;
    bottom: rem(20px);
    left: rem(20px);
  }
}

.ant-tooltip-inner {
  background-color: #ebebed;
  color: $darkcolor;
  font-weight: normal;
  font-size: rem(12px);
  text-align: center;
}

.ant-tooltip-arrow-content {
  background-color: #ebebed;
}
