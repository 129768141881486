.button {
  $el: &;

  &--mobile {
    @include breakpoints(mdl) {
      display: none !important;
    }
  }

  &--desktop {
    display: none;
    @include breakpoints(mdl) {
      display: inline-block;
    }
  }

  &--outlined {
    @extend %button-medium-base;
    border: 1px solid;
    border-radius: 8px;
    padding: rem(8px) rem(12px) rem(8px) rem(8px);
  }

  &--underlined {
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    padding: 0 10px 0 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &--icon {
    padding: 0 !important;
    margin: 0 !important;
  }

  &--text {
    @extend %text-vsmall;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 0;
    padding-right: rem(40px);

    .anticon-left {
      margin-right: rem(8px);
    }
  }

  &--space-r {
    margin-right: rem(8px);
  }

  >span {
    display: table;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

%button-medium-base {
  border-radius: rem(8px);
  min-height: rem(40px);
}

%button-small-base {
  border-radius: rem(8px);

  min-height: rem(34px);
}
